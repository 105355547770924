import React from 'react';

import { Container } from './styles';

const Panel: React.FC = () => {
  return (
    <>
      <Container>
        <h1>Provehito Sistemas</h1>
        <h4 style={{ color: 'red' }}>O vem ai um novo produto CRM</h4>
      </Container>
    </>
  );
};

export default Panel;
